import fetch from '@/utils/fetch.js'
import common from '@/assets/js/common.js'

const baseUrl = common.certifiedJkmc

// 获取文件
export const getFile = (data) => {
	return fetch({
		url: data.url,
		method: 'get',
		responseType: 'blob'
	})
}

// 微信授权
// 获取 accessToken
export const getAccessToken = (data) => {
	return fetch({
		url: `${baseUrl}/weixin/account/accessToken`,
		method: 'get',
		params: data
	})
}
// 获取用户信息
export const getUserInfo = (data) => {
	return fetch({
		url: `${baseUrl}/weixin/account/userinfo`,
		method: 'get',
		params: data
	})
}

// 保存入学意向登记
export const addRegister = (data) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsregister/add`,
		method: 'post',
		data
	})
}

// 获取入学意向登记信息
export const getRegisterInfo = (params) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsregister/${params}`,
		method: 'get',
		
	})
}

// 咨询登记新增
export const addZsxsask = (data) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsask/add`,
		method: 'post',
		data
	})
}

// 体验反馈新增
export const addZxsfeeling = (data) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsfeeling/add`,
		method: 'post',
		data
	})
}

// 查询是否存在入学意向登记数据
export const checkZxsregister = (data) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsregister/check`,
		method: 'post',
		data
	})
}


// 预约探校新增
export const addZxsbooking= (data) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsbooking/add`,
		method: 'post',
		data
	})
}

// 获取探校日程
export const getZxsbooking = (params) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsbooking/list`,
		method: 'get',
		params
	})
}

// 取消探校
export const cancelZxsbooking = (params) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsbooking/cancel`,
		method: 'post',
		params
	})
}

// 获取我的资料 = 入学意向登记
export const getZxsregister = (params) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsregister/list`,
		method: 'get',
		params
	})
}

// 删除意向登记
export const removeZxsregister = (params) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsregister/remove`,
		method: 'post',
		params
	})
}
// 编辑

export const editZxsregister = (data) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsregister/update`,
		method: 'post',
		data
	})
}

// 获取咨询登记详情
export const getZxsaskInfo = (params) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsask/${params}`,
		method: 'get',
		
	})
}


// 获取咨询登记列表
export const getZxsaskList = (params) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsask/list`,
		method: 'get',
		params
	})
}

// 设置已读消息
export const readZxsask = (params) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsask/read`,
		method: 'post',
		params
	})
}

// 获取已读列表
export const getReasklist = (params) => {
	return fetch({
		url: `${baseUrl}/weixin/zxsask/reasklist`,
		method: 'get',
		params
	})
}

// 获取代码表
export const getCodeList = (params) => {
	return fetch({
		url: `${baseUrl}/weixin/dictCode/list`,
		method: 'get',
		params
	})
}