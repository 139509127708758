<template>
	<router-view class="router-view" v-slot="{ Component }">
		<transition :name="transitionName">
			<component :is="Component" />
		</transition>
    </router-view>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
export default {
	name: 'App',
	setup(){
		const router = useRouter()
		const state = reactive({				// reactive--监听
			transitionName: 'slide-left'
		})
		router.beforeEach((to, from) => {
			if(to.meta.index > from.meta.index){
				state.transitionName = 'slide-left'
			} else if(to.meta.index < from.meta.index){
				state.transitionName = 'slide-right'
			} else {
				state.transitionName = ''
			}
		})
		return {
			...toRefs(state)				//reactive在return时候需要toRefs来转换成响应式对象
		}
	},
}
</script>

<style lang="scss" scoped>
.router-view{
	width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    -webkit-overflow-scrolling: touch;
}
</style>
