import common,{ getLocal } from '@/assets/js/common.js'
import router from '../../router'
const userStore = {
    state: {
        token: null,
        dm: getLocal('DM'),
    },
    mutations: {
        SET_TOKEN: (state, code) => {
            state.token = code
        },
        SET_DM:(state, code) => {
            state.dm = code
        },
    },
    actions: {
        setToken({ commit }, params){
            commit('SET_TOKEN', params)
        },
        setDm({ commit }, params){
            commit('SET_DM', params)
        }
    }
}

export default userStore;