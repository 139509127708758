import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import lodash from 'lodash';
import Vant from 'vant';
import vue3videPlay from 'vue3-video-play'
import 'vue3-video-play/dist/style.css'

import 'vant/lib/index.css';
import './assets/iconfont/iconfont.css'             // 自定义图标库
import './assets/css/style.scss'
import './assets/css/common.scss'

const app = createApp(App)


app.use(vue3videPlay)
app.use(Vant)
app.use(router)
app.use(store)
app.use(lodash)


app.mount('#app')

