import  { getAccessToken, getUserInfo } from '../api/api.js'

export const toWxAuthorize = () => {
  let scope = 'snsapi_userinfo'
  let state = 0
  let redirectUri = 'https://lcyh.121xuexi.com/index/index'

  let authorizeUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx515a16fe8755c07c&redirect_uri=' + encodeURIComponent(redirectUri) + '&response_type=code&scope=' + scope + '&state=' + state + '#wechat_redirect';
  
  window.location.href = authorizeUrl
}

export const getWxOpenId = (code) => {
  return new Promise(async (resolve, reject) => {
    let res = await getAccessToken({code: code})
    if(res.code == 200){
      let openId = res.data.openid
      localStorage.setItem('openId', openId)
      let userInfoRes = await getWxUserInfo(code, openId)
      if(userInfoRes == 'success'){
        resolve('success')
      }else{
        reject('err')
      }
    }else{
      reject('err')
    }
  })
}

export const getWxUserInfo = (code, openId) => {
  return new Promise((resolve, reject) => {
    getUserInfo({ code: code, openid: openId}).then(res => {
      localStorage.setItem('userInfo', JSON.stringify(res.data))
      resolve('success')
    }).catch(err => {
      reject('error')
    })
  })
}