import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import {_getQueryId} from '@/assets/js/common.js'
import { toWxAuthorize, getWxOpenId, getWxUserInfo } from '../utils/wx_authorize.js'
import { getUserInfo } from '../api/api'

const base = process.env.NODE_ENV === "production" ? "/" : "/"
const router = createRouter({
    history: createWebHistory(base), // hash模式：createWebHashHistory，history模式：createWebHistory
    routes: [
        {
            path: '/',
            redirect: '/index/index'
        },
        {
            path: '/index',
            name: 'home',
            component: () => import('@/components/layout/index.vue'),
            children:[{
                path: 'index',
                name: 'index',
                component: () => import('@/views/index/index.vue'),
            },{
                path: 'online',
                name: 'online',
                component: () => import('@/views/online/index.vue'),
            },{
                path: 'message',
                name: 'message',
                component: () => import('@/views/message/index.vue'),
            },{
                path: 'impression',
                name: 'impression',
                component: () => import('@/views/impression/index.vue'),
            },{
                path: 'appointment',
                name: 'appointment',
                component: () => import('@/views/bookingAgentSchool/index.vue'),
            },{
                path: 'schedule',
                name: 'schedule',
                component: () => import('@/views/schedule/index.vue'),
            },{
                path: 'questions',
                name: 'questions',
                component: () => import('@/views/questions/index.vue'),
            },{
                path:'consultation',
                name:'consultation',
                component: () => import('@/views/consultation/index.vue'),
            },{
                path:'feedback',
                name:'feedback',
                component: () => import('@/views/feedback/index.vue'),
            },{
                path:'profile',
                name:'profile',
                component: () => import('@/views/profile/index.vue'),
            },{
                path:'checkProfile',
                name:'checkProfile',
                component: () => import('@/views/profile/checkProfile.vue'),
            }]
        },
        {
            path:'/check',
            name:'check',
            component: () => import('@/views/online/components/check.vue'),
        },
        {
            path:'/pdfContent',
            name:'pdfContent',
            component: () => import('@/views/impression/pdfContent.vue'),
        },
        
        
    ]
})

// 路由守卫

router.beforeEach(async (to, from, next) => {
    let isDebug = _getQueryId('isDebug')
    if(!isDebug){
        let isAuth = sessionStorage.getItem('isAuth')
        if(isAuth) {
            next()
            return
        }
        let openId = localStorage.getItem('openId')
        let code = _getQueryId('code')
        if(code){
            if(openId){
                let userinfo = localStorage.getItem('userInfo')
                if(userinfo){
                    sessionStorage.setItem('isAuth', true)
                    next()
                }else{
                    let res = await getWxUserInfo(code, openId)
                    if(res === 'success'){
                        sessionStorage.setItem('isAuth', true)
                        next('/index/index')
                    }
                }
            }else{
                let res = await getWxOpenId(code)
                if(res === 'success'){
                    sessionStorage.setItem('isAuth', true)
                    next('/index/index')
                }
            }
        }else{
            toWxAuthorize()
        }
        
    }else{
        next()
    }
});

export default router