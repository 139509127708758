import axios from 'axios'
import router from '../router'
import store from '../store'
import { Toast } from 'vant'
import common,{UUID,getLocal} from '@/assets/js/common.js'

// 创建axios实例
const service = axios.create({
    timeout: 15000, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(config => {
   


    return config;
}, error => {
    Promise.reject(error);
})

// respone拦截器
service.interceptors.response.use(
    response => {
        // console.log(response.data,'response')
        if (typeof response.data !== 'object') {
            // Toast.fail('服务端异常！')
            return Promise.reject(response)
        }
        if(response.status === 200 || response.status === 304){
            if(response.data.resultCode==1){
            }else{
                
            }
            
            if(response.data.resultCode == 401){
               
            }
            return response.data;
        }else{
            console.log('网络不太给力哦，检查一下您的网络再试吧！')
        }
    },
    error => {
        console.log('err' + error); // for debug
        console.log('网络不太给力哦，检查一下您的网络再试吧！')
        let response = error.response
        if(typeof response.data !== 'object'){
            return Promise.reject(error);
        }
        if(response.status === 401 ){
            if(response.data.message == '未认证，请在前端系统进行认证'){
                
                
            }   
        }
        if(response.status === 500 ){
            Toast.fail(response.data.resultMessage)
            return response.data;
            
        }

        return Promise.reject(error);
    }
);

export default service;
